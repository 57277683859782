import React, { useEffect, useState } from 'react';
import { Box, Button, Text, Heading, Stack, VStack, List, ListItem, ListIcon, useToast } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { createCheckoutSession, createFreeSubscription } from '../../services/api';
import { useNavigate } from 'react-router-dom';



const PricingDollar = (profile) => {
const [basic]=useState("5");
const [standard]=useState("12");
const [professional]=useState("30");

useEffect(() => {
console.log(profile.profile.email);
});
const navigate = useNavigate();  // useNavigate instead of useHistory
const toast=useToast();
  // const handleFree = async () => {
  //   const paymentData = {
  //     plan: 'FREE',
  //     planId: "free"
  //   };
  //   const response = await createFreeSubscription(paymentData);
  //   const session = response.data;
  //   toast({
  //     title: 'Appointment Welcome to PinePlanner.',
  //     description: session,
  //     status: 'success',
  //     duration: 5000,
  //     isClosable: true,
  //   });
  //   window.location.href ="/"; // Redirect to the payment link
  // };

  const handleBasic = async () => {
    const paymentData = {
      plan: 'BASIC',
      planId: "125697",
      amount:basic,
      currency:"usd",
      name:profile.profile.firstName+" "+profile.profile.lastName,
      email:profile.profile.email,
      phoneNumber:profile.profile.phoneNumber,
    };
    const response = await createCheckoutSession(paymentData);
    const session = response.data;
    console.log(session);
    window.location.href = session.data.link; // Redirect to the payment link
  };


  const handleStandard = async () => {
    const paymentData = {
      plan: 'STANDARD',
      planId: "125703",
      amount:standard,
      currency:"usd",
      name:profile.profile.firstName+" "+profile.profile.lastName,
      email:profile.profile.email,
      phoneNumber:profile.profile.phoneNumber,

    };
    const response = await createCheckoutSession(paymentData);
    const session = response.data;
    console.log(session);
    window.location.href = session.data.link; // Redirect to the payment link
  };

  const handleProfessional = async () => {
    const paymentData = {
      plan: 'PROFESSIONAL',
      planId: "126050",
      amount:professional,
      currency:"usd",
      name:profile.profile.firstName+" "+profile.profile.lastName,
      email:profile.profile.email,
      phoneNumber:profile.profile.phoneNumber,
    };
    const response = await createCheckoutSession(paymentData);
    const session = response.data;
    console.log(session);
    window.location.href = session.data.link; // Redirect to the payment link
  };

  return (
    <Box textAlign="center" py={10} px={6} bg="teal.50">
      <Heading as="h2" size="xl" mb={4} color="teal.700">Choose A Plan</Heading>
      <Text fontSize="lg" mb={4} color="teal.600">Click the subscribe button to choose.</Text>
      
      <Stack direction={['column', 'row']} spacing={6} justify="center">
      {/* <VStack borderWidth="1px" borderRadius="lg" p={6} spacing={4} bg="white" shadow="md" textAlign="center">
          <Heading as="h3" size="md" color="teal.600">Free</Heading>
          <Text fontSize="xl" color="teal.500">$0/month</Text>
          <List spacing={3} color="teal.700">
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="teal.500" />
              Book Appointments
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="teal.500" />
              Track your appointment
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="teal.500" />
              See notifications
            </ListItem>
           
          </List>
          <Button colorScheme="teal" onClick={handleFree}>Subscribe</Button>
        </VStack> */}
        <VStack borderWidth="1px" borderRadius="lg" p={6} spacing={4} bg="white" shadow="md" textAlign="center">
          <Heading as="h3" size="md" color="teal.600">Basic</Heading>
          <Text fontSize="xl" color="teal.500">${basic}/month</Text>
          <List spacing={3} color="teal.700">
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="teal.500" />
              50 appointments per month
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="teal.500" />
               Landing Page
            </ListItem>
            {/* <ListItem>
              <ListIcon as={CheckCircleIcon} color="teal.500" />
              Client's billing
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="teal.500" />
              Email notification
            </ListItem> */}
          </List>
          <Button colorScheme="teal" onClick={handleBasic}>Subscribe</Button>
        </VStack>

        <VStack borderWidth="1px" borderRadius="lg" p={6} spacing={4} bg="white" shadow="md" textAlign="center">
          <Heading as="h3" size="md" color="teal.600">Standard</Heading>
          <Text fontSize="xl" color="teal.500">${standard}/month</Text>
          <List spacing={3} color="teal.700">
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="teal.500" />
              200 Appointments per month
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="teal.500" />
               Landing Page
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="teal.500" />
              Client's Billing
            </ListItem>
            {/* <ListItem>
              <ListIcon as={CheckCircleIcon} color="teal.500" />
              Google Calendar Sync
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="teal.500" />
              Email Notification
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="teal.500" />
              Sms Notification (Prepaid)
            </ListItem> */}
          </List>
          <Button colorScheme="teal" onClick={handleStandard}>Subscribe</Button>
        </VStack>

        <VStack borderWidth="1px" borderRadius="lg" p={6} spacing={4} bg="white" shadow="md" textAlign="center">
          <Heading as="h3" size="md" color="teal.600">Professional</Heading>
          <Text fontSize="xl" color="teal.500">${professional}/month</Text>
          <List spacing={3} color="teal.700">
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="teal.500" />
              Unlimited Number of appointments per month
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="teal.500" />
               Landing Page
            </ListItem>
            {/* <ListItem>
              <ListIcon as={CheckCircleIcon} color="teal.500" />
              Client's billing
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="teal.500" />
              Google Calendar Sync
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="teal.500" />
              Sms Notification (Prepaid)
            </ListItem> */}
          </List>
          <Button colorScheme="teal" onClick={handleProfessional}>Subscribe</Button>
        </VStack>
      </Stack>
    </Box>
  );
};

export default PricingDollar;
